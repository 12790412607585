// components

import React from "react";

import { useMediaQuery } from "react-responsive";

import video from "./../../assets/vids/video.mp4";
import logoText from "./../../assets/img/logo-text.png";
import logoLuin from "./../../assets/img/luin-logo-negative.png";

import ReactTooltip from "react-tooltip";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

class Hero extends React.Component<any, any> {
  ref: HTMLElement | null | undefined;

  // @ts-ignore
  public render() {
    return (
      <>
        <Desktop>
          <div className="hero">
            <ReactTooltip />
            <video
              className="hero-video-content"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="card-on-hero">
              <div className="right-info-container">
                <img src={logoText} alt="Ravelin Logo" />
                <h1> NFT-Based Play-and-Earn Castle Game</h1>
                <span className="btn-mint-castle">Coming Soon</span>
              </div>
              <div className="left-social-container">
                <div className="social-content-container">
                  <ul>
                  <li>
                    <a data-tip="Twitter" href="https://twitter.com/RavelinOfficial" target="_blank" rel="noreferrer">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a data-tip="Telegram" href="https://t.me/ravelin_community" target="_blank" rel="noreferrer">
                      <i
                        className="fab fa-telegram-plane"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a data-tip="Medium" href="https://medium.com/@ravelinnetwork" target="_blank" rel="noreferrer">
                      <i className="fab fa-medium-m" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a data-tip="Documents" href="https://docs.ravelin.network/" target="_blank" rel="noreferrer">
                      <i className="fas fa-book" aria-hidden="true"></i>
                    </a>
                  </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="copyright-container">
              <div className="footer-copyright">
                <img src={logoLuin} alt="Luin Team"></img>
              </div>
            </div>
          </div>
        </Desktop>
        <Tablet>
          <div className="hero">
            <ReactTooltip />
            <video
              className="hero-video-content"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="card-on-hero">
              <div className="right-info-container">
                <img src={logoText} alt="Ravelin Logo" />
                <h1> NFT-Based Play-and-Earn Castle Game</h1>
                <span className="btn-mint-castle">Coming Soon</span>
              </div>
              <div className="left-social-container">
                <div className="social-content-container">
                  <ul>
                  <li>
                    <a href="https://twitter.com/RavelinOfficial" target="_blank" rel="noreferrer">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/ravelin_community" target="_blank" rel="noreferrer">
                      <i
                        className="fab fa-telegram-plane"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://medium.com/@ravelinnetwork" target="_blank" rel="noreferrer">
                      <i className="fab fa-medium-m" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://docs.ravelin.network/" target="_blank" rel="noreferrer">
                      <i className="fas fa-book" aria-hidden="true"></i>
                    </a>
                  </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="copyright-container">
              <div className="footer-copyright">
                <img src={logoLuin} alt="Luin Team"></img>
              </div>
            </div>
          </div>
        </Tablet>
        <Mobile>
          <div className="hero">
            <ReactTooltip />
            <video
              className="hero-video-content"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="card-on-hero-container-mb">
              <div className="card-on-hero-mb">
              <img src={logoText} alt="Ravelin Logo" />
              <h1> NFT-Based Play-and-Earn Castle Game</h1>
              <span>Coming Soon</span>
              </div>
            </div>
            <div className="copyright-container-mb">
              <div className="social-content-container-mb">
                <ul>
                  <li>
                    <a href="https://twitter.com/RavelinOfficial" target="_blank" rel="noreferrer">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/ravelin_community" target="_blank" rel="noreferrer">
                      <i
                        className="fab fa-telegram-plane"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://medium.com/@ravelinnetwork" target="_blank" rel="noreferrer">
                      <i className="fab fa-medium-m" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://docs.ravelin.network/" target="_blank" rel="noreferrer">
                      <i className="fas fa-book" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-copyright">
                <a href="https://luin.co" target="_blank" rel="noreferrer">
                <img src={logoLuin} alt="Luin Team"></img>
                </a>
              </div>
            </div>
          </div>
        </Mobile>
      </>
    );
  }
}

export default Hero;
