/*eslint-disable*/
import React from "react";
import logoOnly from "./../../assets/img/logo-only.png";

import logoMobile from "./../../assets/img/ravelin-mb-logo-only.png";

import { useMediaQuery } from "react-responsive";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

// components

class Header extends React.Component<any, any> {
  ref: HTMLElement | null | undefined;

  // @ts-ignore
  constructor(props) {
    super(props);
    this.state = {
      classNameSideNav: "",
    };
  }

  sideNavClick = () => {
    if (!this.state.expandedSideNav) {
      this.setState({ classNameSideNav: "side-nav-open-mb" });
    } else {
      this.setState({ classNameSideNav: "" });
    }
    this.setState({ expandedSideNav: !this.state.expandedSideNav });
  };

  public render() {
    return (
      <>
        <Desktop>
          <nav ref={(r) => (this.ref = r)} className="navbar-ds">
            <ReactTooltip />
            <div className="navbar-container-ds">
              <div className="nav-links-ds">
                <div className="nav-link-menu-container">
                  <ul className="menu">
                    <li>
                      <div data-tip="Coming Soon" className="menu-item-alt">
                        Home
                      </div>
                    </li>
                    <li>
                      <div data-tip="Coming Soon" className="menu-item-alt">
                        About
                      </div>
                    </li>
                    <li>
                      <div data-tip="Coming Soon" className="menu-item-alt">
                        Castles
                      </div>
                    </li>
                    <li>
                      <div data-tip="Coming Soon" className="menu-item-alt">
                        Regions
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="navbar-logo-only-card-ds">
                  <span className="logo-background"></span>
                  <img
                    alt="Ravelin Logo Only"
                    src={logoOnly}
                    className="navbar-logo-only-img-ds"
                  />
                </div>
                <div className="nav-link-menu-container">
                  <ul className="menu">
                    <li>
                      <div data-tip="Coming Soon" className="menu-item-alt">
                        NFT
                      </div>
                    </li>
                    <li>
                      <div data-tip="Coming Soon" className="menu-item-alt">
                        Roadmap
                      </div>
                    </li>
                    <li>
                      <div data-tip="Coming Soon" className="menu-item-alt">
                        Team
                      </div>
                    </li>
                    <li>
                      <div
                        data-tip="Coming Soon"
                        className="whitepaper menu-item-alt"
                      >
                        Whitepaper
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </Desktop>
        <Tablet>
        <nav ref={(r) => (this.ref = r)} className="navbar-mb">
            <div className="navbar-container-mb">
              <div className="navbar-contentify-mb">
                <Link to="#">
                  <img
                    alt="ravelin"
                    className="navbar-logo-text-mb"
                    src={logoMobile}
                  />
                </Link>
                <div className="navbar-menu-mb">
                  <a onClick={this.sideNavClick} className="fas fa-bars"></a>
                </div>
              </div>
            </div>
          </nav>
          <nav className={`side-nav-mb ${this.state.classNameSideNav}`}>
            <div className="nav-links-mb">
              <ul className="menu-mb">
                <li>
                  <a onClick={this.sideNavClick} href="#home">
                    Home
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#about">
                    About
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#castles">
                    Castles
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#token">
                    Token
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#regions">
                    Regions
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#nft">
                    NFT
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#roadmap">
                    Roadmap
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#team">
                    Team
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#whitepaper">
                    Whitepaper
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </Tablet>
        <Mobile>
          <nav ref={(r) => (this.ref = r)} className="navbar-mb">
            <div className="navbar-container-mb">
              <div className="navbar-contentify-mb">
                <Link to="#">
                  <img
                    alt="ravelin"
                    className="navbar-logo-text-mb"
                    src={logoMobile}
                  />
                </Link>
                <div className="navbar-menu-mb">
                  <a onClick={this.sideNavClick} className="fas fa-bars"></a>
                </div>
              </div>
            </div>
          </nav>
          <nav className={`side-nav-mb ${this.state.classNameSideNav}`}>
            <div className="nav-links-mb">
              <ul className="menu-mb">
                <li>
                  <a onClick={this.sideNavClick} href="#home">
                    Home
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#about">
                    About
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#castles">
                    Castles
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#token">
                    Token
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#regions">
                    Regions
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#nft">
                    NFT
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#roadmap">
                    Roadmap
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#team">
                    Team
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#whitepaper">
                    Whitepaper
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </Mobile>
      </>
    );
  }
}

export default Header;
