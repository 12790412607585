// components

import Header from "./../components/Header/Header";

import React from "react";
import Hero from "./app/Hero";

class Landing extends React.Component<any, any> {
  // @ts-ignore
  public render() {
    return (
      <>
        <Header fixed />
        <main>
            <Hero />
        </main>
      </>
    );
  }
}

export default Landing;
